import axios from 'axios';
import { tenantId, Authorization } from './config';
import store from "@/store"
import router from '@/router';
import { refreshToken } from "@/api/login.js";
import { Toast } from 'vant';

const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : '',
  timeout: 10000,
  validateStatus: status => status >= 200 && status < 500,
  // withCredentials: true
});
let toast
// request拦截器  
service.interceptors.request.use(
  config => {
    // console.log(store)
    const userInfo = store.state.userInfo
    config.headers['Authorization'] = 'Basic ' + Authorization;
    config.headers['tenantId'] = tenantId;

    if (userInfo && userInfo.access_token) {
      config.headers['Blade-Auth'] = 'bearer ' + userInfo.access_token
    }

    if (config.showToast) {
      toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '数据加载中...'
      });
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  res => {
    // console.log("请求返回", res);
    const userInfo = store.state.userInfo
    const status = res.data.code || res.status;
    const message = res.data.msg || res.statusText;
    toast && toast.clear()
    if (status === 401) { //请求未授权
      if (userInfo && userInfo.access_token) {
        refreshToken(userInfo.refresh_token)
          .then(user => {
            console.log(user)
            store.dispatch('setUserInfo', user)
            router.go(0) // 刷新页面
          })
      } else {
        router.push('/login')
      }
    } else if (status === 200) {
      return res.data;
    } else {
      Toast(message)
      return Promise.reject(message);
    }
    // if (status !== 200) {
    //   Toast(message)
    //   return Promise.reject(message);
    // }
    // return res.data;
  },
  error => {
    Toast(error)
    return Promise.reject(error);
  }
);

export default service;